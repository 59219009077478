import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { depositSummaryApi } from 'backendServices/ApiCalls';
import { CheckCircleOutline } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import Div from '@jumbo/shared/Div';

const VISIBLE_FIELDS = ['sr', 'sender', 'amount', 'final_amount', 'date'];
const DepositReport = () => {

  const [donationdata, setDonationData] = useState([])
  const [imageurl, setImageUrl] = useState(null);
  const [loader, setLoader] = useState(false)
  const DonationData = () => {
    depositSummaryApi((response) => {
      console.log('response',response)
      const processedData = response?.data?.data?.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setDonationData(processedData);
      setImageUrl(response?.data?.imageURL);
      if (response?.data?.status === "success") {
        console.log("response get Successfully");
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  }

  useEffect(() => {
    DonationData();
  }, [])

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        }
      }
    }
  }




  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [

    {
      field: "index",
      headerName: "#",
      dataGeneratorUniquenessEnabled: true,
      width: 80,
    },
    {
      field: "username",
      headerName: "User Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
    },
    {
      field: "title",
      headerName: "Title",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
    },
    {
      field: "price",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "address",
      headerName: "Address",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "city",
      headerName: "City",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "subscriptionperiod",
      headerName: "Subscription Period",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueFormatter: (params) => {
        return `${params.value} month`;
      },
    },
    {
      field: "picture",
      headerName: "Image",
      width: 150,
      renderCell: (params) => (
        <img
          src={`${imageurl}${params?.row?.image}`} // Assuming your image URLs are constructed like this
          alt={`Product ${params?.row?.title}`}
          style={{ width: "50%", height: "auto" }}
        />
      ),
    },
    {
      field: "createdate",
      headerName: "Create Date",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueFormatter: (params) => {
        const dateValue = new Date(params.value);
        const formattedDate = dateValue.toLocaleDateString('en-GB'); // Adjust the locale as needed
        return formattedDate;
      },
    },
    {
      field: "expirydate",
      headerName: "Expiry Date",
      width: 200,
			valueFormatter: (params) => {
        const dateValue = new Date(params.value);
        const formattedDate = dateValue.toLocaleDateString('en-GB'); // Adjust the locale as needed
        return formattedDate;
      },
    },

  ]

  const rows = donationdata


  if (loader) {
    return <Div
      sx={{
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
      }}
    >
      <CircularProgress sx={{ m: '-40px auto 0' }} />
    </Div>
  }

  return (
    <JumboDemoCard
      title={"Deposit Summary"}
      wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
    >
      <Box sx={{ height: 500, width: 1 }}>

        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[24, 48, 96]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}

        />
      </Box>
    </JumboDemoCard>
  )
}

export default DepositReport