import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { payoutSummaryApi, getpayoutlist, postRequest } from 'backendServices/ApiCalls';
import { Chip, IconButton, Tooltip, Grid } from '@mui/material';
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const VISIBLE_FIELDS = ['sr', 'receiverusername', 'amount', 'final_amount', 'payoutaccount1', 'payoutaccount2', 'createdat'];

const ApprovedPayout = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [copiedRows, setCopiedRows] = useState([]);
  const [usersData, setUsersData] = useState([]);

  let params = {
    status: 'approved',
  };
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 60,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 100,
      editable: true,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 110,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 110,
      editable: true,
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      width: 120,
      editable: true,
      groupable: false,
    },
    {
      field: "bank_account_iban",
      headerName: "Bank Account Iban",
      width: 180,
      editable: true,
      groupable: false,
      
    },
    {
      field: "withdrawal_amount",
      headerName: "Withdrawal Amount",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
  ];

  const UsersData = () => {
    payoutSummaryApi(params, (response) => {
      const processedData = response?.data?.data?.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setUsersData(processedData);
      if (response?.data?.status === 'success') {
        console.log("response get Successfully");
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };



  const rows = usersData;

  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };



  return (
    <JumboDemoCard title={'Approved payouts'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
      <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={usersData}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
      </Box>
    </JumboDemoCard>
  );
};

export default ApprovedPayout;
