import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import MessageItem from "./MessageItem";
import messages from "./fake-data";
import { postRequest } from "backendServices/ApiCalls";

const MessagesList = () => {
	const [notificationData, setNotificationData] = useState([]);
  console.log('notificationData', notificationData)
  const GetNotificationData = () => {
    postRequest(
      "/getnotifications",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setNotificationData(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetNotificationData();
  }, []);
  return (
    <List disablePadding>
      {notificationData.map((item, index) => {
        return <MessageItem key={index} item={item} />;
      })}
    </List>
  );
};

export default MessagesList;
