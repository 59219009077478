import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  postRequest,
} from "backendServices/ApiCalls";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Div from "@jumbo/shared/Div";
import { Field, Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import Typography from "@mui/material/Typography";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Link } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { CKEditor } from "ckeditor4-react";
import MenuItem from "@mui/material/MenuItem";
import ReactPlayer from "react-player";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ManageSupportFile = () => {
  const [image, setImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState(null);
  const [supportFile, setSupportFile] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = React.useState("");
  const [ckeditorContent, setCkeditorContent] = useState("");
  // const [selectedFileName, setSelectedFileName] = useState("");
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const textContent = rowData?.detail;
  const Swal = useSwalWrapper();
  const sweetAlerts = (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        handleDelete(params?.row?.id);
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });
  };

  const handleCkeditorChange = (event) => {
    setCkeditorContent(event.editor.getData());
  };

  const Getmanagesupport = () => {
    postRequest(
      "/getsupportfile",
      "",
      (response) => {
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        // setCkeditorContent(response?.data?.data?.detail)
        setSupportFile(processedData);
        setFileUrl(response?.data?.fileURL);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const handleUpload = (e, field) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];

      // Check if the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result; // Extract base64 encoded string
          if (base64Data) {
            setImage(base64Data);
            console.log("PDF loaded successfully!", base64Data);
          } else {
            console.log("Error loading PDF.");
          }
        };
        reader.readAsDataURL(selectedFile); // Read the selected file as data URL
      } else {
        setalertData({
          show: true,
          message: "Invalid file type. Please select a PDF file.",
          variant: "error",
        });
      }
    }
  };



  useEffect(() => {
    Getmanagesupport();
  }, []);

  const handleCopy = (videolink) => {
    navigator.clipboard
      .writeText(videolink)
      .then(() => {
        console.log("Video link copied to clipboard:", videolink);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  const handleView = (filename) => {
    const fullUrl = `${fileUrl}/${filename}`;
    window.open(fullUrl, "_blank");
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 50,
    },
    {
      field: "supporttype",
      headerName: "Support Type",
      width: 150,
      editable: true,
    },
    {
      field: "videolink",
      headerName: "Links",
      width: 100,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <>
          {params.row.supporttype === "Video Link" ? (
            // Render Copy button for Video Link
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCopy(params.row.videolink)}
            >
              Copy
            </Button>
          ) : params.row.supporttype === "PDF File" ? (
            // Render View button for PDF File
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleView(params.row.filename)}
            >
              View
            </Button>
          ) : (
            // Default rendering (you can adjust this based on your requirements)
            params.row.videolink
          )}
        </>
      ),
    },
    {
      field: "detail",
      headerName: "Detail",
      width: 300,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.row.detail.replace(/\\n/g, "").replace(/\\/g, ""),
          }}
        />
      ),
    },
    {
      field: "createdate",
      headerName: "CreateDate",
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <EditIcon
            style={{ cursor: "pointer", color: "white" }}
            onClick={() => handleOpen(params?.row?.id)}
          />
          <DeleteIcon
            onClick={() => sweetAlerts(params)}
            style={{ cursor: "pointer", color: "red" }}
          />
        </>
      ),
    },
  ];
  const handleOpen = (id) => {
    const rowToEdit = supportFile.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpen(true);
    setFile(rowToEdit?.supporttype);
  };
  const handleClose = () => setOpen(false);

  const handleDelete = (id) => {
    let params = { id: id };
    postRequest(
      "/deletesupportfile",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          // setUserMemberOpen(false)
        } else {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
    setSupportFile((data) => data.filter((row) => row.id !== id));
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  const handleUpdate = (data, setSubmitting, resetForm) => {

    let params = {};

    if (file === "PDF File") {
      params = {
        id: rowData?.id,
        supporttype: file,
        detail: ckeditorContent,
        supportfile: image
      }

    }
    if (file === "Video Link") {
      params = {
        id: rowData?.id,
        supporttype: file,
        detail: ckeditorContent,
        videolink: data.videolink
      }
    }
    postRequest(
      "/updatesupportfile",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setSubmitting(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          setOpen(false)
          Getmanagesupport();
        } else {
          setSubmitting(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setSubmitting(false);
      }
    );
  };

  return (
    <Grid container p={2} alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Manage Support File"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 480, width: 1 }} textAlign="right">
            <Link to="/Add-Support-File">
              <Button variant="contained" sx={{ marginTop: "-50px" }} href="!#">
                Add Support File
              </Button>
            </Link>
            {({ isSubmitting }) => (
              <Div
                sx={{
                  mt: "20%",
                  ml: "45%",
                  mb: "20%",
                }}
              >
                <CircularProgress />
              </Div>
            )}
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={supportFile}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          {/* Update Product Modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Update Product
              </Typography>
              <List disablePadding sx={{ mb: 2 }}>
                <Formik
                  validateOnChange={true}
                  enableReinitialize={true}
                  initialValues={{
                    videolink: rowData?.videolink,
                  }}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleUpdate(data, setSubmitting, resetForm);
                  }}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form
                      style={{ textAlign: "left" }}
                      noValidate
                      autoComplete="off"
                    >
                      <Div sx={{ mt: 1, mb: 1, pl: 2, pr: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label">File</InputLabel>
                          <Select
                            labelId="select-label"
                            id="demo-simple-select"
                            name="file_name"
                            value={file}
                            label="File"
                            onChange={(event) => {
                              const selectedValue = event.target.value;
                              setFile(selectedValue);
                            }}
                            required
                          >
                            <MenuItem value="PDF File">PDF file</MenuItem>
                            <MenuItem value="Video Link">Video Link</MenuItem>
                          </Select>
                        </FormControl>
                      </Div>
                      {file === "PDF File" && (
                        <>
                          <Box >
                            <Div sx={{ margin: "auto", width: "95%" }}>
                              {file === "PDF File" && (
                                <object
                                  data={`${fileUrl}/${rowData?.filename}`}
                                  type="application/pdf"
                                  width="100%"
                                  height="auto"
                                >
                                  <p>Upload PDF New file here</p>
                                </object>
                              )}
                            </Div>
                          </Box>
                          <Div sx={{ mb: 3, pl: 2, pr: 2 }}>
                            <TextField
                              onChange={(event) => {
                                handleUpload(event, "image");
                                setFieldValue(
                                  "image",
                                  event.currentTarget.files[0]
                                );
                              }}
                              required
                              name="image"
                              type="file"
                              margin="normal"
                              sx={{ marginBottom: "0px", width: "100%" }}
                              InputProps={{
                                endAdornment: (
                                  <CameraAltIcon
                                    fontSize={"small"}
                                    color={"success"}
                                  />
                                ),
                              }}
                            />
                          </Div>
                        </>
                      )}

                      {file === "Video Link" && (
                        <>
                          {rowData?.videolink && (
                            <Box>
                              <Div sx={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                                <ReactPlayer width="400px"
                                  height="200px" controls url={rowData?.videolink} />
                              </Div>
                            </Box>
                          )}
                          <Div sx={{ mt: 2, mb: 3, pl: 2, pr: 2 }}>
                            <JumboTextField
                              fullWidth
                              label="Video Link"
                              name="videolink"
                              type="text"
                            />
                          </Div>
                        </>
                      )}
                      <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                        <CKEditor
                          initData={textContent}
                          id="editor"
                          name="editor"
                          config={{
                            contentsCss: [
                              `${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                              `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                            ],
                          }}
                          onChange={handleCkeditorChange}
                        />
                      </Div>

                      <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                        // loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Div>
                    </Form>
                  )}
                </Formik>
              </List>
            </Div>
          </Modal>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default ManageSupportFile;
