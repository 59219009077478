import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Button, Grid, List, ListItem, TextField } from "@mui/material";
import { useState } from 'react';
import { addnews, updateProfilePassword, updatetransactionpassword } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { postRequest } from 'backendServices/ApiCalls';
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CKEditor } from "ckeditor4-react";


// const validationSchema = 
// yup.object({ file: yup
//     .string()
//     .required('File is required'),
//   video_link: yup
//     .string()
//     .required('Video link is required'),
//     image: yup.mixed().required("Image is required"),
//   // editor: yup
//   //   .string()
//   //   .required('Editor link is required'),
// });

const validationSchemas = {
  upload_file: yup.object({
    image: yup.mixed().required("Image is required"),

  }),
  video_link: yup.object({
    videolink: yup.string().required("Video name is required"),
  }),
};
const validationSchema = validationSchemas.upload_file || validationSchemas.video_link;

const AddSupportFile = () => {
  const [file, setFile] = React.useState('');
  const [image, setImage] = useState(null);
  const [ckeditorContent, setCkeditorContent] = useState("");

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: ""
  })
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#fff"
      }
    }
  }
  const textContent =
    'hello word ';
  ;
  const handleChange = (event) => {
    setFile(event.target.value);
  };
  const handleCkeditorChange = (event) => {
    setCkeditorContent(event.editor.getData());
  };
  const handleUpload = (e, field) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
  
      // Check if the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result; // Extract base64 encoded string
          if (base64Data) {
            setImage(base64Data);
            console.log("PDF loaded successfully!", base64Data);
          } else {
            console.log("Error loading PDF.");
          }
        };
        reader.readAsDataURL(selectedFile); // Read the selected file as data URL
      } else {
        setalertData({
          show: true,
          message: 'Invalid file type. Please select a PDF file.',
          variant: 'error',
        });
      }
    }
  };
  

  const handleSubmit = (data, setSubmitting, resetForm) => {
    let params = {
      supporttype: data.file_name,
      detail: ckeditorContent,
    };
  
    // Add properties based on the file type
    if (file === "PDF File") {
      params.supportfile = image;
    }
    if (file === "Video Link") {
      params.videolink = data.videolink;
    }
        postRequest(
            "/addsupportfile",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setOpen(false)
                    // GetallUsers();
                } else {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setOpenUpdate(false);

                }

            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false)

            }
        );
  }
  return (
    <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
      <Grid item sm={8} xs={12}>
        <JumboCardQuick title={"Add Support File"} noWrapper>
          {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
          }

          <List disablePadding sx={{ mb: 2 }}>
            <Formik
              validateOnChange={true}
              enableReinitialize={true}
              initialValues={{
                file_name: file,
                "PDF File": {
                  image: "",
                  editor: "",
                },
                "Video Link": {
                  videolink: "",
                  editor: "",
                },
              }}
              // validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                handleSubmit(data, setSubmitting, resetForm);
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>

                  <Div sx={{ mt: 1, mb: 1, pl: 2, pr: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">File</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="file_name"
                        value={file}
                        label="File"
                        onChange={handleChange}
                        required
                      >
                        <MenuItem value="PDF File">PDF file</MenuItem>
                        <MenuItem value="Video Link">Video Link</MenuItem>
                      </Select>
                    </FormControl>
                  </Div>
                  {file === 'PDF File' && (
                    <Div sx={{ mt: 2, mb: 3, pl: 2, pr: 2 }}>
                      <TextField
                        onChange={(event) => {
                          handleUpload(event, "image");
                          setFieldValue(
                            "image",
                            event.currentTarget.files[0]
                          );
                        }}
                        required
                        name="image"
                        type="file"
                        margin="normal"
                        sx={{ marginBottom: "0px", width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <CameraAltIcon fontSize={"small"} color={"success"} />
                          ),
                        }}
                      />
                    </Div>
                  )}
                  {file === 'Video Link' && (
                    <Div sx={{ mt: 2, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        label="Video Link"
                        name='videolink'
                        type="text"
                      />
                    </Div>
                  )}
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <CKEditor
                      initData={textContent}
                      id="editor"
                      name="editor"
                      config={{
                        contentsCss: [`${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                        `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`],
                      }}
                      onChange={handleCkeditorChange}
                    />
                  </Div>

                  <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                    // loading={isSubmitting}
                    >Submit</LoadingButton>
                  </Div>
                </Form>
              )}
            </Formik>
          </List>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default AddSupportFile;
