import React, { useEffect, useState } from "react";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import { ThemeProvider } from "@mui/material";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { postRequest } from "backendServices/ApiCalls";

const MessagesTriggerButton = () => {
  const { headerTheme } = useJumboHeaderTheme();
	const [totalNotifications, setTotalNotifications] = useState('');

	const GetTotalNotificationsData = () => {
    postRequest(
      "/gettotalnotifications",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setTotalNotifications(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetTotalNotificationsData();
  }, []);
  return (
    <ThemeProvider theme={headerTheme}>
      <Badge badgeContent={totalNotifications} color="primary">
        <NotificationsNoneIcon sx={{ fontSize: "1.5rem" }} />
      </Badge>
    </ThemeProvider>
  );
};

export default MessagesTriggerButton;
