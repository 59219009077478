import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { demodata } from './reportdata';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { roidata } from 'backendServices/ApiCalls';

const VISIBLE_FIELDS = ['sr', 'sender', 'amount', 'date'];
const RoiReport = () => {

  const [userroidata, setUserRoiData] = useState([])
  const RoiData = () => {
    roidata((response) => {
      const processedData = response?.data?.data?.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setUserRoiData(processedData);
    }, (error) => {
      console.log(error?.response?.data);
    })
  }

  useEffect(() => {
    RoiData();
  }, [])


  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        }
      }
    }
  }




  const data = demodata;
  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "index",
      headerName: "#",
      dataGeneratorUniquenessEnabled: true,
      width: 60,
    },
    {
      field: "sender_username",
      headerName: "Sender",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "receiver_username",
      headerName: "Receiver",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "roibonus",
      headerName: "Roi Bonus",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "type",
      headerName: "Type",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "createdat",
      headerName: "Date",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueFormatter: (params) => {
        const dateValue = new Date(params.value);
        const formattedDate = dateValue.toLocaleDateString('en-GB'); // Adjust the locale as needed
        return formattedDate;
      },
    }

  ]

  const rows = userroidata

  return (
    <JumboDemoCard
      title={"ROI Summary"}
      wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  )
}

export default RoiReport