import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  postRequest,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";

function Notifications() {
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const [notificationData, setNotificationData] = useState([]);

  const NotificationData = () => {
    postRequest(
      "/getallnotifications",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setNotificationData(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    NotificationData();
    UpdateNotificationData();
  }, []);

  const UpdateNotificationData = () => {
    postRequest(
      "/updatenotifications",
      '',
      (response) => {
        console.log('Notification Updated Successfully')
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const generateRowId = () => {
    // Generate a random string or use any other logic to create a unique ID
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr#",
      width: 50,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => params.row.id, // Use custom ID or generate one if it doesn't exist
    },
    {
      field: "title",
      headerName: "Title",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },

    {
      field: "message", // New column for Full Name
      headerName: "Message",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
		{
      field: "current_date",
      headerName: "Date",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
  ];

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const rows = notificationData;

  let idCounter = 1; // Initialize the counter

  // Assign a unique sequential ID to each row
  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <JumboDemoCard
      title={"Inactive Users"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Box sx={{ height: 800, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 15 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[15, 30, 75, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
}

export default Notifications;
