import React from "react";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MessageItem = ({item}) => {
  const nav = useNavigate();

  const handleClick = () => {
    // Navigate to the "all-notification" page
    nav('/all-notification');
  };
  return (
    <ListItemButton component={"li"} disableRipple onClick={handleClick}>
      {/* <ListItemAvatar>
        <Avatar />
      </ListItemAvatar> */}
      
        <ListItemText
          primary={
            <Typography variant={"h6"} mb={0.25}>
              {item.title}
            </Typography>
          }
          secondary={
            <Typography noWrap color={"text.secondary"}>
              {item.message}
            </Typography>
          }
        />
      
    </ListItemButton>
  );
};

export default MessageItem;
