import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { payoutSummaryApi, getpayoutlist, postRequest, rejectpayment } from 'backendServices/ApiCalls';
import { Chip, IconButton, Tooltip, Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CancelIcon from '@mui/icons-material/Cancel';
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const VISIBLE_FIELDS = ['sr', 'receiverusername', 'amount', 'final_amount', 'payoutaccount1', 'payoutaccount2', 'createdat'];


const useSweetAlert = (UsersData) => {
  const [alertData, setAlertData] = useState({
    show: false,
    message: '',
    variant: '',
    confirmFunction: null,
    action: '',
  });

  const handleApprove = (id, amount) => {

    return new Promise((resolve) => {
      let params = {
        action: 'approved',
        id: id,
      };

      postRequest(
        '/payoutaction',
        params,
        (response) => {
          if (response?.data?.status === 'success') {
            Swal.fire({
              title: 'payout Approved',
              text: 'payout approved successfully',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK',
            }).then(() => {
              setAlertData((prevAlertData) => ({
                ...prevAlertData,
                show: false,
              }));
            });
            UsersData();
            resolve()
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve()
        }
      );
    })
  };

  const handleReject = (id, amount, reason) => {

    return new Promise((resolve) => {
      let params = {
        action: 'rejected',
        id: id,
        reason: reason,
      };

      postRequest(
        '/payoutaction',
        params,
        (response) => {
          if (response?.data?.status === 'success') {
            Swal.fire({
              title: 'payout Rejected',
              text: 'with reason "' + reason + '"',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'OK',
            }).then(() => {
              setAlertData((prevAlertData) => ({
                ...prevAlertData,
                show: false,
              }));
            });
            UsersData();
            resolve()
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve()
        }
      );
    })
  };

  const showSweetAlert = (title, description, confirmFunction, variant, action) => {
    setAlertData({
      show: true,
      message: description,
      variant: variant,
      confirmFunction: confirmFunction,
      action: action,
    });
  };

  useEffect(() => {
    if (alertData.show) {
      const hasTextField = alertData.action === 'rejected';
      Swal.fire({
        title: alertData.title,
        html: `<div>${alertData.message}</div>${hasTextField ? '<input type="text" id="reason-input" class="swal2-input" placeholder="Reason for rejection" required="">' : ''}`,
        text: alertData.message,
        icon: alertData.variant,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise(async (resolve) => {
            if (hasTextField) {
              const reason = document.getElementById('reason-input').value;
              if (!reason) {
                Swal.showValidationMessage('Please enter a reason for rejection');
                resolve(false);
              } else {
                await alertData.confirmFunction(reason);
                resolve();
              }
            } else {
              await alertData.confirmFunction();
              resolve();
            }
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: () => {
          if (hasTextField) {
            const input = document.getElementById('reason-input');
            if (input) {
              input.focus();
            }
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the case when "Yes" is clicked
        } else {
          // Handle the case when "No" is clicked
        }
      });
    }
  }, [alertData]);

  return {
    showSweetAlert,
    handleApprove,
    handleReject,
  };
};

const PendingPayout = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [copiedRows, setCopiedRows] = useState([]);
  const [usersData, setUsersData] = useState([]);

  let params = {
    status: 'pending',
  };


  const UsersData = () => {
    payoutSummaryApi(params, (response) => {
      const processedData = response?.data?.data?.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      console.log('respose', response)
      setUsersData(processedData);
      if (response?.data?.status === 'success') {
        console.log("response get Successfully");
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };


  const { showSweetAlert, handleApprove, handleReject } = useSweetAlert(UsersData);

  const handleActionClick = (id, amount, action) => {
    setSelectedUserId(id);
    let title = '';
    let description = '';
    let confirmFunction = null;
    let variant = ''

    if (action === 'approved') {
      title = 'Approve payout';
      description = `Are you sure you want to approve this payout of amount $${amount} ?`;
      confirmFunction = () => handleApprove(id, amount);
      variant = 'warning';
    } else if (action === 'rejected') {
      title = 'Reject payout';
      description = `Are you sure you want to reject this payout of amount $${amount} ?`;
      confirmFunction = (reason) => handleReject(id, amount, reason);
      variant = 'error';
    }

    showSweetAlert(title, description, confirmFunction, variant, action);
  };


  const rows = usersData;

  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 60,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 110,
      editable: true,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 110,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 110,
      editable: true,
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      width: 120,
      editable: true,
      groupable: false,
    },
    {
      field: "bank_account_iban",
      headerName: "Bank Account Iban",
      width: 170,
      editable: true,
      groupable: false,
      
    },
    {
      field: "withdrawal_amount",
      headerName: "Withdrawal Amount",
      width: 160,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="Approve" placement="top">
            <IconButton
              onClick={() => handleActionClick(params.row.id, params.row.amount, 'approved')}
              sx={{ color: 'success.main' }}
            >
              <AddTaskIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reject" placement="top">
            <IconButton
              onClick={() => handleActionClick(params.row.id, params.row.amount, 'rejected')}
              sx={{ color: 'error.main' }}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];


  return (
    <JumboDemoCard title={'Pending payouts'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
      <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={usersData}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
      </Box>
    </JumboDemoCard>
  );
};

export default PendingPayout;
